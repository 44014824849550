/* global ajax_obj */

import Foundation from './inc/foundation';
import 'slick-carousel';
import 'jquery.transit';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

$(document).foundation();

/**
 * Add `is-active` class to menu-icon button on Responsive menu toggle
 * And remove it on breakpoint change
 */
const targetElement = document.querySelector('#main-menu');
$(window)
  .on('toggled.zf.responsiveToggle', function () {
    $('.menu-icon').toggleClass(function () {
      if ($(this).hasClass('is-active')) {
        enableBodyScroll(targetElement);
      } else {
        disableBodyScroll(targetElement);
      }
      return 'is-active';
    });
    // $(document.documentElement).toggleClass('no-scroll');
  })
  .on('changed.zf.mediaquery', function () {
    $('.menu-icon').removeClass('is-active');
    // $(document.documentElement).removeClass('no-scroll');
    enableBodyScroll(targetElement);
  });
/**
 * Close responsive menu on orientation change
 */
$(window).on('orientationchange', function () {
  setTimeout(function () {
    if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
      $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    }
  }, 200);
});

$('.header .header-menu .menu-item-has-children > a').on({
  mouseenter: function () {
    if (Foundation.MediaQuery.is('large')) {
      const $this = $(this);
      const wrappers = $('.header-menu .submenu-wrapper');
      if (!$this.hasClass('is-active')) {
        wrappers.removeClass('is-active');
        $this.nextAll('.submenu-wrapper').addClass('is-active');
        $('.header').addClass('menu-open');
        $('.header .header-menu .menu-item-has-children > a').removeClass('is-active');
        $this.addClass('is-active');
      }
    }
  },
  click: function (e) {
    e.preventDefault();
    if (Foundation.MediaQuery.is('medium down')) {
      const $this = $(this);
      const wrappers = $('.header-menu .submenu-wrapper');
      if (!$this.hasClass('is-active')) {
        wrappers.removeClass('is-active');
        $this.nextAll('.submenu-wrapper').addClass('is-active');
        if (Foundation.MediaQuery.is('medium down')) {
          wrappers.slideUp();
          $this.nextAll('.submenu-wrapper').slideDown();
        }
        $('.header').addClass('menu-open');
        $('.header .header-menu .menu-item-has-children > a').removeClass('is-active');
        $this.addClass('is-active');
      } else {
        $this.nextAll('.submenu-wrapper').removeClass('is-active');
        if (Foundation.MediaQuery.is('medium down')) {
          $this.nextAll('.submenu-wrapper').slideUp();
        }
        $('.header').removeClass('menu-open');
        $this.removeClass('is-active');
      }
    }
  },
});

$('header .submenu-wrapper').on('mouseleave', function () {
  if (Foundation.MediaQuery.is('large')) {
    $('.menu-item-has-children > a')
      .removeClass('is-active')
      .next('.submenu-wrapper')
      .removeClass('is-active');
  }
  $(this).removeClass('menu-open');
});

$('.menu-item-has-children').each(function () {
  const $menuItem = $(this);
  const $submenuWrapper = $menuItem.find('.submenu-wrapper');
  const $submenuItems = $submenuWrapper.find('li:not(.hide-for-large):visible');
  const submenuItemCount = $submenuItems.length;
  if (submenuItemCount < 3) {
    $menuItem.addClass('menu-two-items');
  } else {
    $menuItem.removeClass('menu-two-items');
  }
});

$('.footer-menu .menu-item-has-children > a').on('click', function (e) {
  // $('.header-menu .submenu-wrapper').
  if (Foundation.MediaQuery.is('large')) return;

  e.preventDefault();
  $(this).toggleClass('is-active').next('.sub-menu').slideToggle();
  $('.footer-menu .menu-item-has-children > a')
    .not(this)
    .removeClass('is-active')
    .next('.sub-menu')
    .slideUp();
});

$(document).on('click', function (e) {
  const target = $(e.target);
  if (!target.hasClass('header') && !target.parents('.header').length) {
    $('.header-menu .submenu-wrapper').removeClass('is-active');
    $('.header').removeClass('menu-open');
    $('.header .header-menu .menu-item-has-children > a').removeClass('is-active');
  }
});

$(window).on('scroll load', () => {
  $('.header').toggleClass('is-active', $(window).scrollTop() > 50);
});

$('.video__placeholder').on('click', function () {
  const video = $(this).prev();
  const videoElem = video.closest('video');
  const videoIframe = video.find('iframe');
  videoIframe.attr('src', videoIframe.attr('src') + '&autoplay=1');
  if (videoElem.length) {
    videoElem.get(0).play();
  }
  $(this).addClass('hide');
});

$('.js-slider-product').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  rows: false,
  fade: true,
  centerMode: false,
  asNavFor: '.js-slider-product-thumbs',
});
$('.js-slider-product-thumbs').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  asNavFor: '.js-slider-product',
  dots: false,
  arrows: false,
  rows: false,
  centerMode: false,
  centerPadding: '0',
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
});

function slickAnimateDots(slider, dotsToShow, maxBreakpoint = 0) {
  let currentTransform = 0;
  let dotWidth = 0;
  let activeDotWidth = 0;
  let margin = 0;
  let maxTransform = 0;

  slider.on('init breakpoint', function (e, slick) {
    const dotsUl = slick.$dots;
    if (!dotsUl) return;
    const dotsWrapper = dotsUl.closest('.slider__dots');
    if (maxBreakpoint && window.innerWidth > maxBreakpoint) {
      dotsUl.css('transform', 'none');
      dotsWrapper.css('width', 'auto');
      currentTransform = 0;
      return;
    }

    dotsUl.css('position', 'relative');
    const activeDot = dotsUl.find('.slick-active');
    const dots = dotsUl.find('li');

    dotWidth = dotsUl.find('li:not(.slick-active)').first().width();
    margin = parseInt(dots.css('marginRight'));
    activeDotWidth = activeDot.width();
    maxTransform =
      dotsWrapper.get(0).scrollWidth -
      (dotsToShow - 1) * dotWidth -
      activeDotWidth -
      margin * (dotsToShow - 1);

    const activeDotOffset = activeDot.position().left;
    if (activeDotOffset > maxTransform) {
      currentTransform = -maxTransform;
    } else {
      currentTransform = -activeDotOffset;
    }

    dotsUl.css('transition', `transform 0.3s ease-in-out`);
    dotsUl.css('transform', `translateX(${currentTransform}px)`);

    const dotsViewportWidth =
      activeDotWidth + (dotsToShow - 1) * dotWidth + (dotsToShow - 1) * margin;

    dotsWrapper.css({
      width: dotsViewportWidth + 'px',
      overflow: 'hidden',
    });
  });

  slider.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
    if (maxBreakpoint && window.innerWidth > maxBreakpoint) {
      return;
    }
    const dotsUl = slick.$dots;
    const direction = currentSlide - nextSlide > 0 ? 1 : -1;
    currentTransform += direction * (dotWidth + margin);
    if (currentTransform < -maxTransform || nextSlide + 1 === slick.slideCount) {
      currentTransform = -maxTransform;
    }
    if (currentTransform > 0 || nextSlide === 0) {
      currentTransform = 0;
    }
    dotsUl.css('transform', `translateX(${currentTransform}px)`);
  });
}

function initLogosSlider() {
  const sections = $('.logos');
  if (sections.length === 0) return;
  sections.each(function (_, section) {
    const slider = $(section).find('.logos_slider');
    let slidesAmount = slider.find('.logos_slider__item').length;
    if (slidesAmount > 7) {
      slickAnimateDots(slider, 7);
    }

    slider.slick({
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      rows: 0,
      prevArrow: slider.closest('section').find('.slick-prev'),
      nextArrow: slider.closest('section').find('.slick-next'),
      appendDots: slider.closest('section').find('.slider__dots'),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: true,
          },
        },
      ],
    });
  });
}

function initVideoSlider() {
  const sections = $('.video');
  if (sections.length === 0) return;
  sections.each(function (_, section) {
    const slider = $(section).find('.video_slider');

    slider.slick({
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      prevArrow: slider.closest('section').find('.slick-prev'),
      nextArrow: slider.closest('section').find('.slick-next'),
      appendDots: slider.closest('section').find('.slider__dots'),
    });
  });
}

initVideoSlider();

$('.video_slider').on('afterChange', function () {
  $('.video_slider .slick-slide').each(function () {
    const videoElem = $(this).not('.slick-active').find('video');
    const videoIframe = $(this).not('.slick-active').find('iframe');

    const videoPlaceholder = $(this).not('.slick-active').find('.video__placeholder');

    if (videoElem.length) {
      videoElem.get(0).pause();
      videoElem.get(0).currentTime = 0;
      videoPlaceholder.show().removeClass('hide');
    } else if (videoIframe.length) {
      videoIframe.attr('src', videoIframe.attr('src'));
      videoPlaceholder.show().removeClass('hide');
    }
  });
});

initLogosSlider();

$('.js-quote').each((idx, item) => {
  const arrowPrev = $(item).siblings('.btn-wrap').find('.slick-prev');
  const arrowNext = $(item).siblings('.btn-wrap').find('.slick-next');
  const sliderDots = $(item).siblings('.btn-wrap').find('.slick-dots-wrap');
  $(item).slick({
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    rows: 0,
    dots: true,
    prevArrow: arrowPrev,
    nextArrow: arrowNext,
    appendDots: sliderDots,
  });
});

function startCounter() {
  $('.counter-block .counter-items').each(function (i, section) {
    if ($(section).isInViewport()) {
      if ($(section).hasClass('js-active')) return;

      $(section).addClass('js-active');
      $(section)
        .find('.counter-item_number > span')
        .each(function (i, counter) {
          $(counter).countUp();
        });
    }
  });
}

$.fn.isInViewport = function () {
  if (!$(this).length) return;
  let elementTop = $(this).offset().top;
  let elementBottom = elementTop + $(this).outerHeight();

  let viewportTop = $(window).scrollTop();
  let viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(window).on('load scroll', function () {
  const video = $('.hero-section-main_video');
  const videoElem = video.find('video');
  const videoIframe = video.find('iframe');
  if (videoIframe.isInViewport()) {
    videoIframe.attr(
      'src',
      videoIframe.attr('src') + '&autoplay=1&controls=0&loop&mute=1'
    );
  }

  if (videoElem.isInViewport()) {
    videoElem.get(0).play();
  }
});

$(window).on('scroll load', function () {
  startCounter();
});

$.fn.countUp = function () {
  let num = $(this).attr('data-initial');
  let decimal = 0;

  $(this)
    .prop('counter', 0.0)
    .animate(
      {
        counter: num,
      },
      {
        duration: 1000,
        step: function (now) {
          $(this).text(parseFloat(now).toFixed(decimal));
        },
        complete: function () {
          $(this).stop(true, true);
        },
      }
    );
};

$(document).on('click', '.team-member', function () {
  const $this = $(this);
  const wrapper = $this.closest('.team-grid');

  if (Foundation.MediaQuery.is('medium down') && $this.hasClass('active')) {
    $this.removeClass('active');
    $this.next('.team-member-popup').slideUp(function () {
      wrapper.removeClass('popup-active');
    });
  } else {
    if ($this.hasClass('active')) return;
    $('.team-member').removeClass('active');
    $this.addClass('active');
    $('.team-member-popup').hide();
    $(this).next('.team-member-popup').slideDown();
    wrapper.addClass('popup-active');
    wrapper.find('iframe').each((idx, item) => {
      const currSrc = $(item).attr('src');
      $(item).attr('src', currSrc.replace('&autoplay=1', ''));
    });
    wrapper.find('video').each((idx, item) => {
      item.pause();
      item.currentTime = 0;
    });
    $('.team-member-popup__image-placeholder').show().removeClass('hide');
  }
});

$(document).on('click', '.team-member-popup__close', function () {
  const wrapper = $(this).closest('.team-grid');
  const teamItems = $(this).closest('.team-grid').find('.team-member-item');

  $(teamItems).each(function () {
    const videoElem = $(this)
      .find('.team-member-popup__content')
      .prev('.team-member-popup__image')
      .find('video');

    const videoIframe = $(this)
      .find('.team-member-popup__content')
      .prev('.team-member-popup__image')
      .find('iframe');

    const videoPlaceholder = $(this)
      .find('.team-member-popup__content')
      .prev('.team-member-popup__image')
      .find('.team-member-popup__image-placeholder');

    if (videoElem.length) {
      videoElem.get(0).pause();
      videoElem.get(0).currentTime = 0;
      videoPlaceholder.show().removeClass('hide');
    } else if (videoIframe.length) {
      videoIframe.attr('src', videoIframe.attr('src').replace('&autoplay=1', ''));
      videoPlaceholder.show().removeClass('hide');
    }
  });

  $(this)
    .closest('.team-member-popup')
    .slideUp(function () {
      wrapper.removeClass('popup-active');
    })
    .prev('.team-member')
    .removeClass('active');
});

(function () {
  const termIds = [];
  $('button.team-position').on('click', function () {
    const $this = $(this);
    const wrapper = $this.closest('.team').find('.js-team-wrapper');
    const buttonsWrapper = $this.closest('.team-positions');
    const termId = $this.attr('data-term-id');
    const idx = termIds.indexOf(termId);

    if (!termId) {
      termIds.length = 0;
    } else {
      if (idx === -1) {
        termIds.push(termId);
      } else {
        termIds.splice(idx, 1);
      }
    }

    $.ajax({
      url: ajax_obj.ajax_url,
      method: 'GET',
      data: {
        action: 'get_team',
        term_ids: termIds,
      },
      success: (resp) => {
        wrapper.html(resp.html);
        buttonsWrapper.find('.team-position').removeClass('active');
        if (termIds.length) {
          termIds.forEach((attr) => {
            buttonsWrapper.find(`[data-term-id="${attr}"`).addClass('active');
          });
        } else {
          buttonsWrapper.find('.team-position:first-child').addClass('active');
        }
      },
    });
  });
})();

$(document).on('click', 'button.load-more-products', function () {
  const wrapper = $(this).closest('.js-products-wrapper');
  const category = $(this).attr('data-category');
  $.ajax({
    url: ajax_obj.ajax_url,
    method: 'GET',
    data: {
      action: 'get_products',
      offset: 6,
      category,
    },
    success: (resp) => {
      wrapper.html(resp.html);
    },
  });
});

$(document).on('click', '.toggle-grid', function () {
  $('.js-max-height').toggleClass('active');
  $(this)
    .closest('.latest-team-members')
    .find('.latest-members-grid')
    .toggleClass('active');

  $(this).hide();
});

export function initSliderOnMobile(
  selector,
  options = {},
  breakpoint = 'medium',
  setArrows = false,
  setDots = false
) {
  $(selector).each(function (idx, item) {
    $(window).on('load changed.zf.mediaquery', function () {
      let isSlider = $(item).hasClass('slick-initialized');
      if (Foundation.MediaQuery.is(breakpoint)) {
        if (isSlider) {
          $(item).slick('unslick');
        }
      } else if (!isSlider) {
        if (setArrows) {
          $.extend(options, {
            prevArrow: $(item).closest('section').find('.slick-prev'),
            nextArrow: $(item).closest('section').find('.slick-next'),
          });
        }

        if (setDots) {
          $.extend(options, {
            appendDots: $(item).closest('section').find('.slider__dots'),
          });
        }

        $(item).slick(options);
      }
    });
  });
}

initSliderOnMobile('.related-team-members .team-grid', {
  arrows: true,
  dots: true,
  prevArrow: $('.related-team-members .team-grid').closest('section').find('.slick-prev'),
  nextArrow: $('.related-team-members .team-grid').closest('section').find('.slick-next'),
  appendDots: $('.related-team-members .team-grid')
    .closest('section')
    .find('.slider__dots'),
});

let sliderWidth = 0;
const logoSlider = $('.hero-section-main_logos-inner');
const logoSliderItems = logoSlider.find('.hero-section-main_logo');
let slidesPerPage = parseInt(logoSlider.data('slides-per-page'));

if (!slidesPerPage) {
  slidesPerPage = 5;
}

logoSliderItems.each(function (idx, item) {
  const $item = $(item);
  const itemWidth = $item.outerWidth(true);
  sliderWidth += itemWidth;
  if (idx < slidesPerPage) {
    $item.clone().addClass('cloned').appendTo(logoSlider);
  }
});
logoSlider.width(sliderWidth);

/* ---------------------------------------
	Scroll
--------------------------------------- */
$('a[href*="#"]').on('click', function (e) {
  const $this = $(this);
  if ($this.closest('[data-tabs]').length || $this.closest('[data-accordion]').length)
    return;

  const href = $this.attr('href');
  if (href.indexOf('#') === 0) {
    e.preventDefault();
    scrollToBlock(href);
  } else {
    let split = href.split('#');
    let location = window.location.href.replace('#', '');
    if (location === split[0]) {
      e.preventDefault();
      scrollToBlock('#' + split[1]);
    }
  }
});

//Smooth scroll to another page
let locationHash = window.location.hash;
if (locationHash) {
  window.location.hash = '';
  scrollToBlock(locationHash);
}

function scrollToBlock(id) {
  const block = $(id);
  if (!block.length) return;
  const headerHeight = $('.header').height();
  let offset = headerHeight;
  console.log(offset);

  $('html,body').animate(
    {
      scrollTop: $(id).offset().top - offset,
    },
    400
  );
}

/* global gform */
gform.addFilter('gform_datepicker_options_pre_init', function (optionsObj, formId) {
  if (formId === '6') {
    optionsObj.minDate = 0;
  }

  return optionsObj;
});

$('.language-switcher__button').on('click', function () {
  $(this).next('.language-switcher__list').slideToggle();
  $(this).toggleClass('open');
});
